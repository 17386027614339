/*globals VXConfig*/

import React                            from 'react';
import {render, unmountComponentAtNode} from 'react-dom';
import PjaxWrapper                      from '../utils/PjaxWrapper';
import Modal                            from '../components/Modal';
import PropTypes                        from "prop-types";
import {formatCurrency}                 from "../utils/CommonUtils";
import Translations                     from "../utils/Translations";

function run(containerId, className, config) {
	const container      = document.getElementById(containerId);
	const smallScreen    = window.innerWidth < 770 ? true : false;
	const containerWidth = smallScreen ? 500 : 785;

	if (container) {
		const doClose = () => {
			unmountComponentAtNode(container);
		};

		render(<Modal
			className={className}
			dynamicSize={true}
			containerWidth={containerWidth}
		       >
			<ModalContent config={config} closeHook={doClose} />
		</Modal>, container);

		PjaxWrapper.addUnloadFunc(() => {
			unmountComponentAtNode(container);
		});
	}
}

const PAGE_CREDITS   = 1;
const PAGE_VIDEOS    = 2;
const PAGE_FAVORITES = 3;
const PAGE_REWARDS   = 4;

const REWARD_1_VIDEO           = 1;
const REWARD_15_VIDEOS         = 2;
const REWARD_1M_VIP            = 3;
const REWARD_12M_VIP           = 4;
const REWARD_50P_MORE_BALANCE  = 6;
const REWARD_100P_MORE_BALANCE = 7;

class ModalContent extends React.PureComponent {

	constructor(props) {
		super(props);

		const pages = [];

		if (this.props.config.credits > 0) {
			pages.push(PAGE_CREDITS);
		}

		pages.push(PAGE_VIDEOS);
		pages.push(PAGE_FAVORITES);

		if (this.props.config.rewards.length > 0) {
			pages.push(PAGE_REWARDS);
		}

		this.state = {
			currentPageIndex: 0,
			pages:            pages,
			pageCount:        pages.length,
		};

		this.language = VXConfig.language;

		this.getRewards    = this.getRewards.bind(this);
		this.getPage       = this.getPage.bind(this);
		this.onClickButton = this.onClickButton.bind(this);
		this.getButton     = this.getButton.bind(this);
		this.getDots       = this.getDots.bind(this);
	}

	getEuroByCredits(credits) {
		return <span className={"h-color-special"}>{formatCurrency(credits * 0.01, false)}</span>;
	}

	getRewards() {
		const rewards = [];
		const texts   = [];

		for (let i = 0; i < this.props.config.rewards.length; i++) {
			let imgSrc = null;
			let text   = null;
			switch (parseInt(this.props.config.rewards[i])) {
				case REWARD_1_VIDEO:
					imgSrc = "/assets/img/modal/pornme/pornme2vx_icon_video.svg";
					text   = '1 Video';
					break;
				case REWARD_15_VIDEOS:
					imgSrc = "/assets/img/modal/pornme/pornme2vx_icon_video.svg";
					text   = '15 Videos';
					break;
				case REWARD_1M_VIP:
					imgSrc = "/assets/img/modal/pornme/pornme2vx_icon_vip.svg";
					text   = "1 " + Translations.get('DateMonth') + " VIP";
					break;
				case REWARD_12M_VIP:
					imgSrc = "/assets/img/modal/pornme/pornme2vx_icon_vip.svg";
					text   = "1 " + Translations.get('DateYear') + " VIP";
					break;
				case REWARD_50P_MORE_BALANCE:
					imgSrc = "/assets/img/modal/pornme/pornme2vx_icon_bonus50.svg";
					text   = '50% ' + Translations.get('More').toLowerCase() + ' ' + Translations.get('NavbarGuestMenuBalance');
					break;
				case REWARD_100P_MORE_BALANCE:
					imgSrc = "/assets/img/modal/pornme/pornme2vx_icon_bonus100.svg";
					text   = '100% ' + Translations.get('More').toLowerCase() + ' ' + Translations.get('NavbarGuestMenuBalance');
					break;
			}

			rewards.push(
				<span className={"pornme-migration-reward"}>
					<img className={"pornme-migration-reward-icon"} src={imgSrc} />
				</span>
			);

			texts.push(<span className={"pornme-migration-reward-text"}>{text}</span>);

			if (i + 1 !== this.props.config.rewards.length) {
				rewards.push(' + ');
				texts.push(' + ');
			}
		}

		return (
			<div>
				<div className={"pornme-migration-rewards"}>{rewards}</div>
				<div className={"pornme-migration-texts"}>{texts}</div>
			</div>
		);
	}

	getPage() {
		let content = null;

		switch (this.state.pages[this.state.currentPageIndex]) {
			case PAGE_CREDITS:
				content = (
					<div>
						<img className={"pornme-migration-content-image"} src={"/assets/img/modal/pornme/guthaben_02.jpg"} />
						<span className={"pornme-migration-content-wrapper"}>
							<div className={"pornme-migration-content-l1"}>
								<img className={"pornme-migration-content-icon"} src={"/assets/img/modal/pornme/pornme_icon_credits.svg"} />
								<span className={"icon -icon-complete-arrow-right-line pornme-migration-content-arrow"} />
								<img className={"pornme-migration-content-icon"} src={"/assets/img/modal/pornme/vx_icon_euro.svg"} />
							</div>
							<div className={"pornme-migration-content-l2"}>{this.language === 'de' ? "Deine restlichen Credits wurden in EURO umgewandelt:" : "Your remaining have been converted to EURO:\n"}</div>
							<div className={"pornme-migration-content-l3"}>{this.props.config.credits + ' Credits = '} {this.getEuroByCredits(this.props.config.credits)}</div>
						</span>
					</div>
				);
				break;
			case PAGE_VIDEOS:
				content = (
					<div>
						<img className={"pornme-migration-content-image"} src={"/assets/img/modal/pornme/meinevideos.jpg"} />
						<span className={"pornme-migration-content-wrapper"}>
							<div className={"pornme-migration-content-l1"}>
								<img className={"pornme-migration-content-icon"} src={"/assets/img/modal/pornme/pornme_icon_video.svg"} />
								<span className={"icon -icon-complete-arrow-right-line pornme-migration-content-arrow"} />
								<img className={"pornme-migration-content-icon"} src={"/assets/img/modal/pornme/vx_icon_video.svg"} />
							</div>
							<div className={"pornme-migration-content-l2"}>{this.language === 'de' ? "Deine gekauften Videos werden innerhalb von 24 Stunden zur Verfügung stehen:" : "Your purchased videos will be available within 24h:"}</div>
							<div className={"pornme-migration-content-l3"}>{this.language === 'de' ? "Mein VISIT-X " : "My VISIT-X "} / <span
								className={"h-color-highlight"}
							                                                                                                            >Videos</span></div>
						</span>
					</div>
				);
				break;
			case PAGE_FAVORITES:
				content = (
					<div>
						<img className={"pornme-migration-content-image"} src={"/assets/img/modal/pornme/meinefavoriten.jpg"} />
						<span className={"pornme-migration-content-wrapper"}>
							<div className={"pornme-migration-content-l1"}>
								<img className={"pornme-migration-content-icon"} src={"/assets/img/modal/pornme/pornme_icon_favorite.svg"} />
								<span className={"icon -icon-complete-arrow-right-line pornme-migration-content-arrow"} />
								<img className={"pornme-migration-content-icon h-color-highlight"}
								     src={"/assets/img/modal/pornme/vx_icon_favorite.svg"}
								/>
							</div>
							<div className={"pornme-migration-content-l2"}>{this.language === 'de' ? "Deine Favoriten findest Du unter:" : "You can find your favorites at:"}</div>
							<div className={"pornme-migration-content-l3"}>{this.language === 'de' ? "Mein VISIT-X " : "My VISIT-X "} / <span
								className={"h-color-highlight"}
							                                                                                                            >{this.language === 'de' ? "Favoriten" : "Favorites"}</span></div>
						</span>
					</div>
				);
				break;
			case PAGE_REWARDS:
				content = (
					<div>
						<div className={"pornme-migration-rewards-h2"}>{this.language === 'de' ? "Hier sind deine Rewards:" : "These are your rewards:"}</div>
						{this.getRewards()}
					</div>
				);
				break;
			default:
				content = null;
		}

		return <div className={"pornme-migration-content"}>{content}</div>;
	}

	onClickButton(e) {
		e.preventDefault();
		e.stopPropagation();

		if (this.state.currentPageIndex === this.state.pageCount - 1) {
			this.props.closeHook();
		} else {
			this.setState({currentPageIndex: this.state.currentPageIndex + 1});
		}
	}

	getButton() {
		const text = this.state.currentPageIndex === this.state.pageCount - 1 ? (this.language === 'de' ? "Los geht's" : "Let's go") : (this.language === 'de' ? 'Weiter' : 'Proceed');

		return <span className="button -btn-color-special" onClick={this.onClickButton}>{text}</span>;
	}

	getDots() {
		const dots = [];

		for (let i = 0; i < this.state.pageCount; i++) {
			const active = i === this.state.currentPageIndex;

			dots.push(<span className={"pornme-migration-dot" + (active ? ' -is-active' : '')} />);
		}

		return <div className={"pornme-migration-dots"}>{dots}</div>;
	}

	render() {
		const button = this.getButton();
		const page   = this.getPage();
		const dots   = this.getDots();

		return (
			<div className="pornme-migration-box">
				<img className="pornme-migration-logo" src="/assets/img/vxlogo_shade_redblack.svg" />
				<div className="pornme-migration-headline">{this.language === 'de' ? "Willkommen bei VISIT-X" : "Welcome to VISIT-X"}</div>
				{page}
				{dots}
				<div className="pornme-migration-button">{button}</div>
			</div>
		);
	}
}

ModalContent.propTypes = {
	closeHook: PropTypes.func,
	config:    PropTypes.shape({
		credits: PropTypes.number,
		rewards: PropTypes.arrayOf(PropTypes.number),
	}),
};

export {run};
